.form-register h3 {
  text-align: center;
  width: 100%;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: auto;
  max-width: 600px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 3px 0;
}

.register-form button {
  margin-top: 20px;
}

.btn-logar {
  color: #06103a;
  text-decoration: none;
  margin-top: 20px;
  text-align: center;
}

.content-register {
  height: 95vh;
}

.logo-register {
  width: 200px;
  position: absolute;
  bottom: 50px;
  right: 20px;
}