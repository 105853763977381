.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #06103a
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 600px;
  border-radius: 20px;
  padding: 40px 0;
  box-shadow: 0px 0px 5px 0 #fff;
}

.login-form h2,
.login-form label {
  color: #fff;
}

.login-form a {
  margin-top: 20px;
  letter-spacing: 1.3px;
  text-decoration: underline;
}

.login-form input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
}

.login-form button {
  width: 500px;
}

.logo {
  width: 250px;
  height: auto;
  margin-bottom: 20px;
}

.password-input {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #06103a;
  opacity: .9;
  transform: translateY(-50%);
  cursor: pointer;
}

.btn-reset-password {
  text-decoration: underline;
  margin-top: 20px;
  letter-spacing: 1.3px;
  font-size: 14px
}

@media screen and (max-width: 500px) {
  .login-form input{
    width: 300px;
  }

  .login-form button {
    width: 300px;
  }
  
}