.filter-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.custom-datepicker {
  display: flex;
  gap: 10px;
  width: 50%;
}

.tdLimitada {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  cursor: pointer;
}

.tableDemandas tr td:nth-child(4) {
  white-space: normal;
  min-width: 200px;
}

.filter-button {
  padding: 15px 10px;
}

.soma-valores {
  color: #06103a;
  width: 100%;
  text-align: end;
  padding: 15px;
  margin-top: 40px;
  border-radius: 6px;
  box-shadow: 1px 1px 5px 0 #ccc;
  display: flex;
  justify-content: space-between;

  .valores {
    text-align: center;
  }
}

.btn-edit,
.btn-del {
  background: none;
  color: #06103a;
  font-size: 16px;
  width: auto;
  padding: 5px;
  margin: 13% auto;
  cursor: pointer;
}

.btn-edit:hover,
.btn-del:hover {
  scale: .99;
}

.date-picker-container {
  grid-column: span 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.data-datapicker {
  display: flex;
  gap: 20px;
}

.content-table-demandas {
  overflow-x: auto;
}

.children-modal {
  display: flex;
  justify-content: center;
  gap: 10px;

}

.children-modal>div {
  width: 100%;
}


.objeto{
  cursor: pointer;
}
/* Estilo do overlay do modal */
.modal-text-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-text-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 50%;
  max-height: 50%;
  overflow-y: auto;
  position: relative;
}

.modal-text-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  padding: 0;
  margin: 0;
  width: auto;
  color: #06103a;
  cursor: pointer;
}
.modal-text-close:hover{
  background: none;
}




@media (max-width: 500px) {
  .date-picker-container {
    flex-direction: column;
  }

  .filter-section {
    align-items: flex-start;
    flex-direction: column;
  }

  .soma-valores {
    text-align: center;
  }

  .soma-valores{
    flex-direction: column;
    gap: 20px;
  }

  .modal-text-content {
    max-width: 60%;
  }

}