.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #06103a;
  padding: 6px 14px;
  transition: all 0.5s ease;
  color: #fff;
  z-index: 999;
}

.sidebar.open {
  width: 250px;

}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.logo-details img {
  width: 40px;
  margin-right: 6px;
}

.sidebar .logo-details img {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-left: 3px;
  margin-top: 20px;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details img,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: center;
  right: 0;
  width: auto;
}

.sidebar .nav-list {
  margin-top: 40px;
  height: 100%;
}

.sidebar h3 {
  font-size: 14px;
  margin: -25px 0 40px 0;
  text-align: center;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.sidebar.open h3 {
  display: block;
  color: #fff;
}

.sidebar li {
  position: relative;
  list-style: none;
  cursor: pointer;
}

.sidebar.open .nav-list .toolti {
  display: none;
}

.nav-list .toolti {
  display: block;
  position: absolute;
  background-color: #fff;
  color: #06103a;
  padding: 6px 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px 0px;
  top: 0;
  left: 60px;
  display: none;
  z-index: 3;
  transition: opacity .5s ease;
}

.nav-list li:hover .toolti {
  display: block;
}

.sidebar li div :last-child {
  margin-left: auto;
}

.sidebar li a,
.sidebar li div {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #06103a;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  gap: 20px;
}

.sidebar li a:hover,
.sidebar li div:hover {
  background: #fff;
  color: #06103a;
}

#div-map {
  background-color: #040b27;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 150px;
}

.sidebar.open #div-map {
  align-items: center;
}

#a-map {
  padding: 5px;
  background: transparent;
  opacity: .5;
  width: 100%;
}

#a-map:hover {
  color: #fff;
  opacity: 1;
}

.sidebar.open #div-map {
  width: auto;
}

.sidebar li a .links_name,
.sidebar li div span {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name,
.sidebar.open li div span {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover .i,
.sidebar li div:hover span,
.sidebar li div:hover .i {
  transition: all 0.5s ease;
  color: #06103a;
}

.sidebar li .i {
  height: 20px;
  line-height: 50px;
  font-size: 18px;
}

.sidebar li.profile {
  position: fixed;
  height: 50px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #040b27;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar .profile #log_out a {
  background: transparent;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 20px;
}
.sidebar .profile #log_out {
  padding-bottom: 70px;
  background: none;
  font-size: 20px;
}

.sidebar .profile span {
  color: #fff;
  position: absolute;
  font-size: 20px;
  display: none;
}

.sidebar.open .profile span {
  display: block;
}

.sidebar.open #hide-icon {
  display: block;
}

.sidebar #hide-icon {
  display: none;
}

@media (max-width: 500px) {
  .sidebar li .tooltip {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .usuario {
    display: flex;
    flex-direction: row;
    font-size: 12px;
  }

  .logo span {
    display: none;
  }

  .collapsed {
    width: 0px;
  }

}