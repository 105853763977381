.reader-cliente {
  >.gradient-bar {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    >.search-bar{
      width: 60%;
      margin: 0;
    }
  }
}

/* Details Container */
.details-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

/* Header da página */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  color: var(--azul);
}

/* Formulário */
.details-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Grid dos campos */
.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.form-group.full-width {
  grid-column: 1 / -1;
  /* Faz o campo ocupar toda a linha no grid */
}

/* Cada campo */
.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Observações - Textarea */
textarea {
  width: 100%;
  /* Ocupa toda a largura disponível */
  height: 200px;
  /* Define uma altura padrão */
  padding: 10px;
  /* Espaçamento interno */
  font-size: 16px;
  /* Tamanho da fonte */
  border: 1px solid #ccc;
  /* Borda padrão */
  border-radius: 5px;
  /* Bordas arredondadas */
  resize: none;
  /* Permite redimensionar apenas verticalmente */
}


/* Botão de Salvar */
.save-button {
  align-self: center;
  background: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.save-button:hover {
  background: #218838;
}


/* button de olho */
.btn-icon {
  padding: 0;
  margin: 0;
  width: auto;
  background: none;
  color: var(--azul);

  &:hover {
    background-color: transparent;
  }
}