*  {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  margin-bottom: 20px;
  color:#000;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.container {
  position: relative;
  min-height: 100vh;
  width: calc(100% - 78px);
  top: 0;
  left: 78px;
  transition: all 0.5s ease;
  padding: 10px;
  z-index: 2;
}

.sidebar.open~.container {
  left: 250px;
  width: calc(100% - 250px);
}

.content {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

button {
  color: white;
  background-color: #6a7fff;
  width: 200px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  transition: .3s;
}

button:hover {
  background-color: #5167f7;
}

/* Formulario */
.formulario-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 20px;
  padding: 40px 0;
}

.formulario-container h2 {
  color: #06103a;
  margin-bottom: 20px;
}

.formulario-container form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 90%;
}

.formulario-container label {
  margin-top: 10px;
  color: #06103a;
}

.formulario-container button {
  height: 45px;
  margin-top: 32px;
}

@media (max-width: 500px) {
  .formulario-container form {
    display: flex;
    flex-direction: column;
  }
}

input,
select {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 3px;
}

/* Tabelas  */

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 15px 8px;
  text-align: left;
  font-size: 14px;
  word-wrap: break-word;
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

th {
  background-color: #06103a;
  color: #fff;
  font-size: 12px;
}

tr:hover {
  background-color: #fff;
}
