.container-dash {
  height: 110vh;
}

.dash {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  flex: 1;
  margin: 0;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  height: 120px;
  min-width: 200px;
  transition: .1s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.card::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 70px;
  height: 50px;
  background: #fff;
  border-radius: 0 20px 20px 0;
  opacity: 0.7;
  z-index: 1;
}

.icon-card {
  position: absolute;
  top: 18px;
  left: 18px;
  font-size: 24px;
  z-index: 1;
  color: #06103a;
}

.description {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.gradient-bar {
  background: linear-gradient(to left, #06103a, #647fff);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.search-bar {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #06103a;
  padding: 10px;
  width: 80%;
  margin: 20px auto;
}

.content-table-chart {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
  margin: 60px auto;
  position: relative;
}

.table td {
  border: 1px solid #ccc;
  padding: 20px;
  text-align: end;
  color: #06103a;
  font-weight: 700;
}

.graficos {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.graficos h3 {
  background-color: #06103a;
  color: #fff;
  padding: 5px;
  width: 100%;
  font-size: 14px;
  border-radius: 10px 10px 0 0;
}

.graficos>div {
  width: 50%;
  height: 350px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.username {
  position: absolute;
  top: -15px;
  left: 10px;
  background-color: #06103a;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
}

.logo-dash {
  width: 100px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}


@media screen and (max-width: 768px) {
  .container-dash {
    height: auto;
  }
  .graficos {
    flex-direction: column;
    width: 100%;
  }

  .graficos>div {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .logo-dash {
    width: 100px;
    right: 40%;
    bottom: 0;
  }

  .container-dash {
    padding-bottom: 100px !important;
  }
}