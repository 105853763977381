/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 800px;
  height: 98vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.close-button {
  color: #000;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  font-size: 20px;
  font-weight: bold;
}

.close-button:hover {
  scale: 1.1;
  background: none;
}
