.contas{
  display: flex;
  gap: 20px;
}


.contas>div:nth-child(1) {
  max-height: 500px;
  width: 40%;
}

.contas>div {
  width: 60%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.form-contas {
  color: #06103a;
}

.contas h2 {
  color: #06103a;
}

.valorTotal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #06103a;
  padding: 10px;
  margin: 40px auto;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.valorTotal div p {
  font-size: 12px;
  margin-top: 5px;
}

.valorTotal span {
  color: #fe4900;
}

.custom-datepicker .input-date-picker {
  height: 8px;
  width: 100px;
}

.lista-contas {
  margin-top: 20px;
  overflow-x: auto;
}

.lista-contas thead th {
  text-align: center;
}

.lista-contas tbody td:nth-child(5),
.lista-contas tbody td:nth-child(6),
.lista-contas tbody td:nth-child(2) {
  text-align: center;
}

.lista-contas tbody td:nth-child(4) {
  text-align: end;
}

.lista-contas tbody td:nth-child(3) {
  font-weight: bold;
  color: #06103a;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
}

.div-right select{
  width: 70%;
  padding: 8px;
  margin-right: 10px;
}

.div-right button {
  width: calc(30% - 10px);
}

.vencida {
  color: red !important;
}

.paga {
  color: green !important;
}


@media (max-width: 600px) {
  .contas{
    flex-direction: column;
  }
  
  
  .contas>div:nth-child(1) {
    max-height: auto;
    width: 100%;
  }
  
  .contas>div {
    width: 100%;
    padding: 10px;
  }

  .div-right button {
    font-size: 8px;
  }

}