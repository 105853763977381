.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 40px 0 10px;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;

  >h2 {
    color: #06103a;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }

  >div {
    display: flex;
    justify-content: center;

    >button {
      background: none;
      padding: 0 5px;
      width: 50px;

      .i {
        border: 1.8px solid #06103a;
        border-radius: 50%;
        font-size: 20px;
        padding: 6px 10px;
        color: #06103a;
      }
    }
  }
}

.content-day{
  overflow: auto;
}

/* Grid do calendário */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}

.calendar-header {
  font-weight: bold;
  text-align: center;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  color: #06103a;
  font-size: 14px;
}

.calendar-day {
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  position: relative;
  min-height: 80px;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  &:hover {
    background: #f9f9f9;
  }
}

.calendar-day span {
  font-size: 13px;
  font-weight: 500;
  margin: 8px 0;
  color: #888;
}

/* Modal Styling */
.modal-details {
  height: 100%;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #06103a;
  margin-bottom: 15px;
}

.modal-details p {
  font-size: 16px;
  color: #333;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.modal-buttons button {
  padding: 10px;
  background-color: #06103a;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons button:hover {
  background-color: #4b5e73;
}

/* Agendamentos no calendário */
.calendar-day .agendamento {
  padding: 2px;
  background-color: #add8e6;
  color: #06103a;
  border-radius: 6px;
  margin: 4px 0;
  font-size: 13px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.calendar-day .agendamento:hover {
  opacity: 1;
}

.btnAtender,
.calendar-day .agendamento[status="Atendido"] {
  background-color: #a8e6a1 !important;
  /* Verde claro para atendido */
}

.btnCancelar,
.calendar-day .agendamento[status="Cancelado"] {
  background-color: #f8a8a8 !important;
  /* Vermelho claro para cancelado */
}

.calendar-day .agendamento[status="Expirado"] {
  background-color: #d3d3d3;
  /* Cinza para expirado */
}

/* Dasvazios */
.empty-day {
  background-color: transparent;
  pointer-events: none;
}

/* Dia atual */
.today {
  border: 1.5px solid #a8e6a1;
  border-radius: 5px;
}

/* Responsividade */
@media (max-width: 800px) {
  .calendar-day {
    min-height: 60px;
  }

  .calendar-navigation>h2 {
    font-size: 18px;
  }

  .modal-details {
    width: 100%;
  }

  .calendar-day .agendamento {
    font-size: 10px;

  }
}

@media (max-width: 500px) {
  /* .calendar-grid {
    width: 80vw;
  } */
  .calendar-day {
    min-height: 50px;
  }

  .calendar-navigation>h2 {
    font-size: 16px;
  }

  .modal-details {
    width: 100%;
    padding: 15px;
  }
}