.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 30px 10px 20px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 3px;
  width: 30px;
  height: 10px;
  display: flex;
  align-items: center;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}