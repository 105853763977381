.btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  gap: 10px;
  border-bottom: 2px solid #ddd;
}

label {
  display: block;
  margin: 15px 0 0 5px;
  font-weight: bold;
}

textarea {
  width: 100%;
  height: 400px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-size: 18px;
  color: #06103a;
  font-weight: 800;
}


/* Roda */
.inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  padding: 5px 0 10px 0;
}

.inputs input {
  border: 2px solid #06103a;
  border-radius: 8px;
  text-align: center;
}

.labels {
  color: #06103a;
  font-size: 14px;
  margin-bottom: 5px;
}

.container-btn-gerar {
  width: 100vw;
  position: absolute;
  bottom: 123em;
  padding-right: 100px;
  text-align: right;
}

.container-btn-swot {
  width: 100vw;
  position: absolute;
  bottom: 74em;
  padding-right: 100px;
  text-align: right;
}

.container-btn-perdas {
  width: 100vw;
  position: absolute;
  bottom: 30em;
  padding-right: 100px;
  text-align: right;
}

.btn-gerado {
  background-color: #28a745;
  color: white;
  cursor: not-allowed;
}

.btn-gerado:hover {
  background-color: #28a745;
}


@media screen and (max-width: 500px) {
  .inputs {
    width: 90vw;
    padding: 0 0 10px 0;
    margin: 0;
    flex-wrap: wrap;
    gap: 20px;
  }

  .inputs label {
    font-size: 10px;
  }
}